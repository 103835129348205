<template>
	<div>
		<Header :navbg='true'></Header>
		<div class="content">
			<div class="login">
				<div class="logo">您好，小院邻里</div>
				<div class="warp">
					<div class="mobile item" :style="mobileStyle">
						<div class="label">手机号</div>
						<div class="input-wrap">
							<input type="number" name="mobile" placeholder="请输入手机号码" v-model="mobile" @focus="focus('mobile')" @blur="blur('mobile')"
							 class="mobilePhone" />
						</div>
					</div>
					<div class="code item" :style="codeStyle">
						<div class="label">验证码</div>
						<div class="input-wrap">
							<input type="number" name="code" placeholder="请输入验证码" v-model="code" @focus="focus('code')" @blur="blur('code')"
							 class="mobileCode" @input="lengthLimit" />
							<div class="sent" :class="canCode ? 'sentProhibit' : ''" @click="getCode">
								<span class="title">{{ codeTitle }}</span>
							</div>
						</div>
					</div>
					<div class="clause">
						注册登录即代表你同意
						<span class="privacy-policy" @click="privacyPolicy">《隐私条款》</span>
						所约定的条款
					</div>

				</div>
			</div>
			<div class="loginWarp">
				<div class="loginBtn" :class="canLogin ? 'loginProhibit' : ''" @click="login">注册/登录</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from '@/components/head2022.vue';
	import utils from '@/common/utils.js';
	import {
		mapState
	} from 'vuex';

	export default {
		name: 'login',
		data() {
			return {
				mobileStyle: {},
				codeStyle: {},
				mobile: '',
				code: '',
				loading: false,
				nsecond: 60,
				appCount: null,
				externalLinks: null
			};
		},
		components: {
			Header
		},
		computed: {
			...mapState(['ocApi']),
			year: function() {
				return utils.date('Y');
			},
			codeTitle: function() {
				if (this.loading) {
					return this.nsecond + 's';
				} else {
					return '点击获取';
				}
			},
			canCode: function() {
				if (!this.mobile || this.loading) {
					return true;
				}
				return false;
			},
			canLogin: function() {
				if (this.mobile && this.code) {
					return false;
				} else {
					return true;
				}
			}
		},
		created: function() {
			let externalLinks = this.$route.query.url;
			if (externalLinks) {
				this.externalLinks = decodeURI(externalLinks);
			}
		},
		methods: {
			lengthLimit() {
				if (this.code.length > 6) {
					this.code = this.code.slice(0, 6)
				}
			},
			focus: function(e) {
				this[e + 'Style'] = {
					borderBottom: '1px solid #00D596'
				};
			},
			blur: function(e) {
				this[e + 'Style'] = {
					borderBottom: '1px solid #E6E6E6'
				};
			},
			getCode: utils.debounce(
				function() {
					let that = this;
					if (that.canCode) {
						return;
					}

					that.$axios
						.post(that.ocApi + 'account/send_sms', {
							telephone: that.mobile
						})
						.then(res => {
							if (res.data.status == 1) {
								that.$toast.success(res.data.message);
								that.loadingCode();
							} else {
								that.$toast.fail(res.data.message);
							}
						})
						.catch(error => {
							that.$toast.fail(error.response.data.message);
						});
				},
				500,
				true
			),
			loadingCode: function() {
				let that = this;
				that.loading = true;
				that.appCount = setInterval(() => {
					that.nsecond -= 1;
					if (that.nsecond == 0) {
						clearInterval(that.appCount);
						that.loading = false;
						that.nsecond = 60;
					}
				}, 1000);
			},
			login: utils.debounce(
				function() {
					let that = this;
					if (that.canLogin) {
						return;
					}

					let query = {}
					var value = localStorage.getItem("inviterQuery");
					if (value) {
						query = {
							...JSON.parse(value)
						}
					}

					that.$axios
						.post(that.ocApi + 'account/telephone_login', {
							account: that.mobile,
							sms_code: that.code,
							code: 'GW',
							...query
						})
						.then(res => {
							if (res.data.status == 1) {
								if (that.externalLinks) {
									window.location.href = that.externalLinks + '&uid=' + res.data.user_info.customer_id + '&access-token=' +
										res.data.user_info['access_token'];
									return
								}

								that.$toast.success(res.data.message);

								that.$cookies.set("xy_access_token", res.data.user_info.access_token, "1m", '/', 'xiaoyuan.com.cn');
								that.$store.dispatch('loginLogout', res.data.user_info)

								if (that.$route.query && that.$route.query.redirectName) {
									that.$router.replace({
										name: that.$route.query.redirectName
									});
								} else {
									that.$router.replace({
										name: 'member'
									});
								}
							} else {
								that.$toast.fail(res.data.message);
							}
						})
						.catch(error => {
							that.$toast.fail(error.response.data.message);
						});
				},
				500,
				true
			),
			privacyPolicy: utils.debounce(function() {
				this.$router.push({
					name: "privacyPolicy"
				})
			}, 1000, true),
		}
	};
</script>

<style lang="scss" scoped>
	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: #cecdd3;
	}

	::-moz-placeholder {
		/* Firefox 19+ */
		color: #cecdd3;
	}

	:-ms-input-placeholder {
		/* IE 10+ */
		color: #cecdd3;
	}

	:-moz-placeholder {
		/* Firefox 18- */
		color: #cecdd3;
	}

	.content {
		width: 100%;
		height: 100vh;
		padding: 0 25px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;

		.login {
			padding-top: 87px;

			.logo {
				font-size: 30px;
				color: #323850;
				font-weight: 600;
				font-family: PingFang SC;
			}

			.warp {
				width: 100%;
				margin-top: 4px;

				.item {
					width: 100%;
					border-bottom: 1px solid #e6e6e6;
					padding: 21px 0 24px;

					.label {
						font-size: 11px;
						color: #323850;
					}

					.input-wrap {
						display: flex;
						margin-top: 30px;
						justify-content: space-between;

						.mobilePhone,
						.mobileCode {
							font-size: 15px;
							flex: auto;
						}

						.sent {
							width: 88px;
							height: 30px;
							display: flex;
							justify-content: center;
							align-items: center;
							border-radius: 100px;
							box-sizing: border-box;
							border: 1px solid transparent;
							background-clip: padding-box, border-box;
							background-origin: padding-box, border-box;
							background-image: linear-gradient(90deg, #fff, #fff),
								linear-gradient(90deg, #01ccf6 0%, #10ea8d 100%);

							&.sentProhibit {
								border: 1px solid #cdced3;
								background: none;

								.title {
									background-image: linear-gradient(90deg,
										#999999 0%,
										#999999 100%);
								}
							}

							.title {
								background-image: linear-gradient(90deg,
									#01ccf6 0%,
									#10ea8d 100%);
								-webkit-background-clip: text;
								-webkit-text-fill-color: transparent;
								font-size: 13px;
								line-height: 13px;
							}
						}
					}
				}

				.clause {
					font-size: 11px;
					color: #9b9da9;
					margin-top: 25px;

					>span {
						background: linear-gradient(90deg,
							rgba(27, 215, 255, 1) 0%,
							rgba(0, 235, 87, 1) 100%);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
				}
			}
		}

		.loginWarp {
			margin-top: auto;
			margin-bottom: 50px;
			width: 100%;

			.loginBtn {
				width: 80%;
				height: 45px;
				color: #ffffff;
				background: linear-gradient(90deg,
					rgba(27, 215, 255, 1) 0%,
					rgba(0, 235, 129, 1) 100%);
				margin: auto;
				border-radius: 100px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
</style>
